import React, { Component } from 'react';
import { withFirebase } from '../../Firebase'
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
});

class ColourPicker extends Component {
  constructor(props) {
    super(props);

    this.handleOpen = this.handleOpen.bind(this);
    this.handleSelectColour = this.handleSelectColour.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      anchorElement: null,
      colourOptions: [],
    };
  }

  componentDidMount() {
    this.props.firebase.colours().get().then(coloursSnap => {
      let colours = [];
      coloursSnap.forEach(colourDoc => {
        let section = { ...colourDoc.data(), uid: colourDoc.id };
        colours.push(section);
      });
      this.setState({ colourOptions: colours });
    });
  }

  handleOpen(event) {
    this.setState({ anchorElement: event.currentTarget });
  }

  handleSelectColour(colourKey) {
    this.props.setSelectedColour(colourKey);
    this.handleClose();
  }

  handleClose() {
    this.setState({ anchorElement: null });
  }

  render() {
    const { classes, selectedColourValue } = this.props;
    const { anchorElement, colourOptions } = this.state;

    const styles = selectedColourValue ? { style: { backgroundColor: selectedColourValue } } : {};
    let menuTitle = { primary: "Pick Colour" };
    if (selectedColourValue) {
      const pickedColour = colourOptions.find(colour => colour.colourValue === selectedColourValue);

      menuTitle.secondary = pickedColour ? pickedColour.title : "*No title for picked colour*";
    }

    return (
      <div className={classes.root}>
        <List component="nav">
          <ListItem button onClick={this.handleOpen} {...styles} >
            <ListItemText {...menuTitle} />
          </ListItem>
        </List>
        <Menu
          anchorEl={anchorElement}
          keepMounted
          open={Boolean(anchorElement)}
          onClose={this.handleClose}
        >
          {colourOptions.map(colour =>
            <MenuItem

              key={colour.title + colour.colourValue}
              selected={colour.colourValue === selectedColourValue}
              onClick={() => this.handleSelectColour(colour.colourValue)}
              style={{ backgroundColor: colour.colourValue }}
            >
              {colour.title}
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }
}

ColourPicker = withStyles(styles, { name: 'ColourPicker' })(ColourPicker);
export default withFirebase(ColourPicker);
