import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    app.firestore().settings({
      cacheSizeBytes: app.firestore.CACHE_SIZE_UNLIMITED
    });

    app.firestore().enablePersistence({ synchronizeTabs: true });

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
    this.functions = app.functions();
  }

  doSignOut = () => this.auth.signOut();

  auth = this.auth;
  storage = this.storage;
  functions = this.functions;

  db = () => this.db;
  // Portal API
  portals = () => this.db.collection('portals');
  portal = id => this.db.collection('portals').doc(`${id}`);
  colours = () => this.db.collection('colours');
  colour = id => this.db.collection('colours').doc(`${id}`);
  icons = () => this.db.collection('icons');
  icon = id => this.db.collection('icons').doc(`${id}`);
  index = () => this.db.collection('index').doc('index');

  portalViaUriName = uri =>
    this.db.collection('portals').where('uri', '==', uri).limit(1);
}

export default Firebase;
