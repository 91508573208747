import React, { Component, Fragment, createRef } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../Firebase';
import withTracker, { trackEvent } from '../../Tracker/withTracker';

import { Redirect } from "react-router-dom";
import NoAuthScreen from "../Auth/NoAuthPage";

import Typography from '@material-ui/core/Typography';
import CenteredProgressIndicator from '../../Widgets/CenteredProgressIndicator'

import PortalSection from './PortalSection';
import LinksSection from './LinksSection';
import ActionUtils from '../../../utils/ActionUtils'
import Box from '@material-ui/core/Box';

import EditPortalSectionDialog from '../../Widgets/Admin/EditPortalSectionDialog';
import { Container as RGSContainer, Row as RGSRow } from 'react-grid-system';
import ConfirmDeleteDialog from '../../Widgets/Admin/ConfirmDeleteDialog';
import * as Helper from '../../../utils/Helper.js';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';


//setConfiguration({ defaultScreenClass: 'sm', gridColumns: 8 });

const styles = (theme) => ({
  emptyStateIcon: {
    fontSize: theme.spacing(12)
  },

  button: {
    marginTop: theme.spacing(1)
  },

  buttonIcon: {
    marginRight: theme.spacing(1)
  },

  mainBox: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8)
    },
    [theme.breakpoints.down('md')]: {
      //We've removed most page margins but need to push left side out as grid has right margins
      //and will look un-even otherwise
      marginLeft: "15px"
    },
    marginTop: theme.spacing(4)
  },

  headerCosyPortal: {
    paddingBottom: theme.spacing(3)
  },
});

const blankState = {
  portal: null,
  exists: true,
  sections: [],
  linksVisible: false,
  activeTile: null,
  activeTileAnalyticsPath: "",
  loading: true,
  sectionEditing: null,
  deletingSection: false,
};

class PortalPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...blankState, ...props.location.state };

    this.handleTileClick = this.handleTileClick.bind(this);
    this.createNewSection = this.createNewSection.bind(this);
    this.deleteSection = this.deleteSection.bind(this);
    this.saveEditData = this.saveEditData.bind(this);
    this.editColour = this.editColour.bind(this);
    this.closeEditDialog = this.closeEditDialog.bind(this);
    this.openEditDialog = this.openEditDialog.bind(this);
    this.editTitle = this.editTitle.bind(this);
    this.handleMoveSection = this.handleMoveSection.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.editPortalType = this.editPortalType.bind(this);

    this.linksRef = createRef();
  }

  fetchPortalFromFirebase() {
    let portalToFetch = this.props.match.params.key;
    if (portalToFetch === "PersonalPortal") {
      // a personal portal
      let personalPortalID = Helper.getPersonalPortalID(this.props.user.email);
      // if we're opening a personal portal for the first time we have to create it
      this.props.firebase.portal(personalPortalID).get().then((personalPortalDoc) => {
        if (personalPortalDoc.exists) {
          this.loadPortalFromFirebaseDoc(personalPortalDoc);
        } else {
          // doesn't exist, create it
          let personalPortalData = {
            title: `${this.props.user.displayName}'s Portal`,
            isPersonalPortal: true,
          }
          personalPortalDoc.ref.set(personalPortalData).then(() => {
            this.loadPortalFromFirebaseDoc({
              data: () => { return personalPortalData },
              id: personalPortalID,
              ref: personalPortalDoc.ref
            });
          });
        }
      });
    } else {
      // a normal portal
      this.props.firebase.portalViaUriName(portalToFetch).get().then(portalDocList => {
        if (!portalDocList.empty) {
          const portalDoc = portalDocList.docs[0]; // actually returning a list of 0-1 here
          this.loadPortalFromFirebaseDoc(portalDoc);
        } else {
          this.setState({
            loading: false,
            exists: false
          });
        }
      });
    }
  }

  loadPortalFromFirebaseDoc(portalDoc) {
    this.unsubscribe1 = portalDoc.ref.onSnapshot(portalSnap => {
      let portal = { ...portalSnap.data(), uid: portalDoc.id, docRef: portalDoc.ref };

      this.setState({
        portal: portal,
        loading: false,
      });
    });

    this.unsubscribe2 = portalDoc.ref.collection('sections').orderBy('sequence').onSnapshot(sectionsSnap => {
      let sections = [];
      let priorSectionRef = null;
      sectionsSnap.forEach(sectionDoc => {
        let section = {
          ...sectionDoc.data(),
          uid: sectionDoc.id,
          docRef: sectionDoc.ref,
          priorSectionRef: priorSectionRef,
          nextSectionRef: null
        };
        sections.push(section);
        //Store this as prior for next loop iteration
        priorSectionRef = sectionDoc.ref

        //and add this to prior section
        if (sections.length > 1) {
          sections[sections.length - 2].nextSectionRef = sectionDoc.ref
        }
      });
      this.setState({ sections: sections });
    });
  }

  componentDidMount() {
    if (!!this.props.user) {
      this.fetchPortalFromFirebase();
    } else {
      this.setState({
        loading: false,
        exists: false
      });
    }

    this.historyUnsubscribe = this.props.history.listen((location, action) => {
      if (action !== 'REPLACE') {
        const pathBits = this.props.history.location.pathname.split('/');
        if (pathBits.length === 3) {
          this.setState({ ...blankState }, () => this.fetchPortalFromFirebase());
        }
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe1 && this.unsubscribe1();
    this.unsubscribe2 && this.unsubscribe2();
    this.historyUnsubscribe && this.historyUnsubscribe();
  }

  handleTileClick(tile) {
    tile.hasLinks ? this.handleTileWithLinksClick(tile) : this.handleTileWithActionClick(tile);
  }

  handleTileWithLinksClick(tile) {
    if (this.state.activeTile == null || tile.docRef.path !== this.state.activeTile.docRef.path) {
      trackEvent('TileWithLinks', 'Click', tile.analyticsPath);
      let linksRef = this.linksRef;
      this.setState({
        activeTile: tile,
        activeTileAnalyticsPath: tile.analyticsPath,
        linksVisible: true
      }, () => {
        linksRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      });
    } else {
      //clicked same tile again so hide the links panel
      this.hideLinksPanel();
    }
  }

  handleTileWithActionClick(tile, section) {
    trackEvent('TileWithAction', 'Click', tile.analyticsPath);
    this.hideLinksPanel();
    if (tile.action && tile.action !== "") {
      if (ActionUtils.isActionSupportedOnClient(tile.action)) {
        if (tile.action.startsWith("mailto")) {
          window.location.href = tile.action;
        } else if (ActionUtils.isInElectronApp()) {
          ActionUtils.handleLinkInElectron(tile.action);
        } else {
          window.open(tile.action);
        }
      } else {
        trackEvent('TileWithAction', 'UnsupportedClient', tile.analyticsPath);
        this.popupSnackbar(ActionUtils.getLinkNotSupportedText(), "error");
      }
    } else {
      trackEvent('TileWithAction', 'UndefinedAction', tile.analyticsPath);
      this.popupSnackbar("No action defined for tile", "error");
    }
  }

  handleMoveSection(section, direction) {
    var batch = this.props.firebase.db.batch();
    var otherSectionRef = direction === "left" ? section.priorSectionRef : section.nextSectionRef;
    otherSectionRef.get().then(function (snap) {
      const targetSequence = snap.get("sequence");
      batch.update(section.docRef, { sequence: Number(targetSequence) });
      batch.update(otherSectionRef, { sequence: Number(section.sequence) });
      batch.commit();
    });
  }

  hideLinksPanel() {
    this.setState({
      activeTile: null,
      linksVisible: false
    });
  }

  popupSnackbar(text, type) {
    this.props.handleSnackbarOpen(text, type);
  }

  createNewSection() {
    const newNumSections = Helper.getNextSequence(this.state.sections);
    const editDialog = this.openEditDialog;
    this.state.portal.docRef
      .collection('sections')
      .add({ title: `Section ${newNumSections}`, sequence: newNumSections })
      .then(function (newSectionRef) {
        //Read back the created details and map to a section JS object so we can then pop open the editDialog
        //where user can then type a real title etc
        newSectionRef.get()
          .then(function (newSectionSnap) {
            let section = {
              ...newSectionSnap.data(),
              uid: newSectionSnap.id,
              docRef: newSectionRef
            };
            editDialog(section);
          });
      });
  }

  deleteSection() {
    this.state.sectionEditing.docRef.delete();
    this.closeDeleteDialog();
    this.closeEditDialog();
  }

  saveEditData() {
    this.state.sectionEditing.docRef.update({ ...this.state.sectionEditing });
    this.closeEditDialog();
  }

  editColour(text) {
    let sectionEditing = this.state.sectionEditing;
    sectionEditing.tileColour = text;
    this.setState({ sectionEditing: sectionEditing });
  }

  closeEditDialog() {
    this.setState({ sectionEditing: null });
  }

  editTitle(text) {
    let sectionEditing = this.state.sectionEditing;
    sectionEditing.title = text;
    this.setState({ sectionEditing: sectionEditing });
  }

  openEditDialog(section) {
    // can't have undefined going to firebase
    const colour = section.tileColour ? section.tileColour : "";

    let newSection = {
      docRef: section.docRef,
      title: section.title,
      oldTitle: section.title,
      sequence: section.sequence,
      tileColour: colour,
    };
    this.setState({ sectionEditing: newSection });
  }

  openDeleteDialog() {
    this.setState({ deletingSection: true });
  }

  closeDeleteDialog() {
    this.setState({ deletingSection: false });
  }

  editPortalType() {
    const portal = this.state.portal;
    portal.docRef.update({ portalType: (portal.portalType && portal.portalType === 'cosy') ? 'normal' : 'cosy' });
  }

  render() {
    const { portal, sections, exists, linksVisible, loading, sectionEditing, deletingSection } = this.state;
    const { classes, isAdmin, user } = this.props;
    const isSignedIn = !!user;

    if (!isSignedIn) {
      return (<NoAuthScreen />);
    } else if (loading) {
      return (<CenteredProgressIndicator />);
    }

    // need to add the 'add new' tile here, not in the firestore listener as that won't fire often
    var sectionsToMap = Array.from(sections);
    if (isAdmin) {
      sectionsToMap.push({ isAddNew: true, uid: "addNew" });
    }

    if (exists) {

      const isCosyPortalLayout = portal && (portal.portalType === "maintenance" || portal.portalType === "cosy");
      const headerClassName = isCosyPortalLayout ? classes.headerCosyPortal : classes.headerNormal;
      const portalsSection = sectionsToMap.map(section =>
        <PortalSection
          {...section}
          isAdmin={isAdmin}
          createNewSection={this.createNewSection}
          handleTileClick={this.handleTileClick}
          handleMoveSection={(direction) => this.handleMoveSection(section, direction)}
          clickEditSection={() => this.openEditDialog(section)}
          key={section.uid}
          portalStyle={isCosyPortalLayout ? "cosy" : "normal"}
          analyticsPath={this.state.portal.title + "/"}
          alreadyInGrid={isCosyPortalLayout}
          isFirstSection={section.priorSectionRef === null}
          isLastSection={section.nextSectionRef === null} 
          canCopyTiles={!portal.isPersonalPortal}
          />
      );

      // if this is a new portal, prompt to turn on admin mode
      const portalIsEmpty = sectionsToMap.length === 0;
      const shouldShowAdminHint = portalIsEmpty && (user.canBeAdmin || portal.isPersonalPortal);

      return (
        <Fragment>
          <ConfirmDeleteDialog
            isOpen={deletingSection}
            onClose={this.closeDeleteDialog}
            onConfirmDelete={this.deleteSection}
          />
          <EditPortalSectionDialog
            sectionEditing={sectionEditing}
            closeEditDialog={this.closeEditDialog}
            editTitle={this.editTitle}
            editColour={this.editColour}
            deleteSection={this.openDeleteDialog}
            saveEditData={this.saveEditData}
          />
          <Box className={classes.mainBox}>
            <Typography variant="h1" className={headerClassName} noWrap>{portal.title}</Typography>
            {isAdmin && <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={isCosyPortalLayout}
                    value={isCosyPortalLayout}
                    onChange={this.editPortalType}
                  />
                }
                label="Show cosy layout?"
              />
            </FormGroup>}
            {portalIsEmpty &&
              <Box style={{ marginTop: "20px" }}>
                <Typography>This portal is empty...</Typography>
                {shouldShowAdminHint &&
                  <Typography>Click the user menu in the top right to turn on Admin Mode and add content.</Typography>
                }
              </Box>
            }
            <Box style={{ marginLeft: "-15px", marginTop: "20px" }}>
              {isCosyPortalLayout ?
                <RGSContainer fluid style={{ lineHeight: '32px' }}>
                  <RGSRow align="end">
                    {portalsSection}
                  </RGSRow>
                </RGSContainer>
                :
                <div>{portalsSection}</div>
              }
            </Box>
          </Box>
          <LinksSection
            isAdmin={isAdmin}
            visible={linksVisible}
            ref={this.linksRef}
            {...this.state.activeTile}
            popupSnackbar={this.popupSnackbar.bind(this)}
            analyticsPath={this.state.activeTileAnalyticsPath}
          />
        </Fragment>
      );
    } else {
      //the below will 404 it's self, but just send to a bogus route so it shows our 404 page
      return (<Redirect to="/notFound" />)
    }
  }
}

//https://stackoverflow.com/questions/45704681/react-material-ui-export-multiple-higher-order-components/45708498#45708498
PortalPage = withTracker(PortalPage);
PortalPage = withStyles(styles, { name: 'PortalPage' })(PortalPage);
export default withFirebase(PortalPage);