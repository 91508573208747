import React from "react";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
//import white from '@material-ui/core/colors/white';
import CssBaseline from '@material-ui/core/CssBaseline';

// A theme with custom primary and secondary color.
// It's optional.
const theme = createMuiTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#fff",
    },
    secondary: {
      main: "#FFBC51",
      light: "#FFBC51",
      dark: "#FFBC51",
      blah: "#fff"
    },
    accent: {
      main: "#2C3A73"
    },
    background: {
      default: "#1D294D"
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        backgroundColor: "#1D294D",
      }
    },
    MuiTypography: {
      h1: {
        fontSize: "2.25rem",
      },
      h2: {
        fontSize: "1.125rem"
      },
      body1: {
        fontSize: "1rem",
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8rem"
      }
    },
    MuiBackdrop: {
      root: {
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        backdropFilter: "blur(1.5px)"
      },
    },
    MuiDialog: {
      paper: {
        backgroundColor: "#1D294D"
      }
    },
    MuiButton: {
      outlinedSecondary: {
        borderColor: "#102645",
        color: "#102645"
      },
    },
    MuiDrawer: {
      paper: {
        background: "#1D294D",
      },
    },
  },
});

function withRoot(Component) {
  function WithRoot(props) {
    // MuiThemeProvider makes the theme available down the React tree
    // thanks to React context.
    return (
      <MuiThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
