import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../Firebase';

import LaunchScreen from '../LaunchScreen';

import Typography from '@material-ui/core/Typography';


const styles = (theme) => ({
  center: {
    position: 'absolute',
    top: '20%',
    width: '100%'
  }
});

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
    };
  }

  componentDidMount() {
    var code = this.getURLParameter('code');
    var state = this.getURLParameter('state');
    var error = this.getURLParameter('error');

    if (error) {
      this.setState({ errorMessage: error });
    } else if (code) {

      var tokenFunctionURL = 'https://us-central1-' + process.env.REACT_APP_PROJECT_ID + '.cloudfunctions.net/token';
      var reqLink = tokenFunctionURL +
        '?code=' + encodeURIComponent(code) +
        '&state=' + encodeURIComponent(state);

      fetch(reqLink, { mode: 'cors' })
        .then((result) => {
          result.json().then((data) => {
            if (data.token) {
              this.props.firebase.auth.signInWithCustomToken(data.token)
                .then(() => this.props.history.push("/"));
            } else {
              console.error(data);
              this.setState({ errorMessage: 'Login error, minting Azure token in firebase auth. ' + data.error });
            }
          });
        });
    } else {
      this.setState({ errorMessage: 'No token, no error. This page only works if accessed as part of the auth redirect.' });
    }
  }


  getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=([^&;]+?)(&|#|;|$)').exec(this.props.location.search) ||
      [null, ''])[1].replace(/\+/g, '%20')) || null;
  }

  render() {
    const { errorMessage } = this.state;
    const { classes } = this.props;

    return (
      errorMessage ?
        <Typography variant="h1" align="center" className={classes.center}>{errorMessage}</Typography>
        :
        <LaunchScreen />
    );
  }
}

LoginPage = withStyles(styles, { name: 'LoginPage' })(LoginPage);
export default withFirebase(LoginPage);