import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import { withFirebase } from '../Firebase';
import withTracker from '../Tracker/withTracker';
import { Container as RGSContainer, Row as RGSRow, Col as RGSCol } from 'react-grid-system';

import NoAuthScreen from './Auth/NoAuthPage';
import Hidden from '@material-ui/core/Hidden';

import Tile from '../Widgets/Tile';
import CenteredProgressIndicator from '../Widgets/CenteredProgressIndicator'
import EditPortalDialog from '../Widgets/Admin/EditPortalDialog';
import ConfirmDeleteDialog from '../Widgets/Admin/ConfirmDeleteDialog';
import * as Helper from '../../utils/Helper.js';

//setConfiguration({ defaultScreenClass: 'sm', gridColumns: 8 });

const styles = (theme) => ({
  homeSubtitle: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },

  mainBox: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8)
    },
    [theme.breakpoints.down('md')]: {
      //We've removed most page margins but need to push left side out as grid has right margins
      //and will look un-even otherwise
      marginLeft: "15px"
    },
    marginTop: theme.spacing(4)
  },
});

const personalPortalTitle = 'Your Portal';

class HomePage extends Component {
  constructor(props) {
    super(props);

    this.openEditDialog = this.openEditDialog.bind(this);
    this.closeEditDialog = this.closeEditDialog.bind(this);
    this.saveEditData = this.saveEditData.bind(this);
    this.editTitle = this.editTitle.bind(this);
    this.editUri = this.editUri.bind(this);
    this.deletePortal = this.deletePortal.bind(this);
    this.editIcon = this.editIcon.bind(this);
    this.addNewPortal = this.addNewPortal.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);

    this.state = {
      loading: false,
      portals: [],
      portalEditing: null,
      deletingPortal: false
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.unsubscribe = this.props.firebase
      .portals()
      .where('isPersonalPortal', '==', false)
      .orderBy('sequence')
      .onSnapshot(snapshot => {
        let portals = [];
        let priorPortalRef = null; // track what portal to swap with when moving left or right
        snapshot.forEach(portalDoc => {
          let portal = {
            ...portalDoc.data(),
            uid: portalDoc.id,
            docRef: portalDoc.ref,
            isNewSection: false,
            priorPortalRef: priorPortalRef,
            nextPortalRef: null
          };
          portals.push(portal);

          priorPortalRef = portalDoc.ref;
          if (portals.length > 1) {
            portals[portals.length - 2].nextPortalRef = portalDoc.ref;
          }
        });

        this.setState({
          portals,
          loading: false,
        });
      })
  }

  openEditDialog(portal) {
    // can't have undefined going to firebase
    const icon = portal.icon ? portal.icon : "";
    const portalType = portal.portalType ? portal.portalType : "normal";

    let newPortal = {
      docRef: portal.docRef,
      title: portal.title,
      oldTitle: portal.title,
      uri: portal.uri,
      icon: icon,
      sequence: portal.sequence,
      portalType: portalType
    };
    this.setState({ portalEditing: newPortal });
  }

  closeEditDialog() {
    this.setState({ portalEditing: null });
  }

  saveEditData() {
    this.state.portalEditing.docRef.update({ ...this.state.portalEditing });
    this.closeEditDialog();
  }

  editTitle(text) {
    let portalEditing = this.state.portalEditing;
    // don't confuse people by naming a real portal the same as a personal portal
    portalEditing.title = text.replace(personalPortalTitle, '');
    this.setState({ portalEditing: portalEditing });
  }

  editUri(text) {
    let portalEditing = this.state.portalEditing;
    // portal uri must be sanitary - only keep alphanumeric input
    // also don't allow usage of personal portal uri prefix
    portalEditing.uri = text.replace(/[^a-z0-9]/gi, '').replace('PersonalPortal', '');
    this.setState({ portalEditing: portalEditing });
  }

  editIcon(text) {
    let portalEditing = this.state.portalEditing;
    portalEditing.icon = text;
    this.setState({ portalEditing: portalEditing });
  }

  deletePortal() {
    this.state.portalEditing.docRef.delete();
    this.closeDeleteDialog();
    this.closeEditDialog();
  }

  addNewPortal() {
    const editDialog = this.openEditDialog;
    const newUri = String(new Date().getTime());
    this.props.firebase
      .portals()
      .add({ title: "New Portal", sequence: Helper.getNextSequence(this.state.portals), uri: newUri, isPersonalPortal: false })
      .then((newPortalRef) => {
        newPortalRef.get()
          .then((newPortalSnap) => {
            let portal = {
              ...newPortalSnap.data(),
              uid: newPortalSnap.id,
              docRef: newPortalRef
            };
            editDialog(portal);
          });
      });
  }

  handlePortalClick(portal) {
    if (portal.isAddNew) {
      this.addNewPortal();
    } else if (portal.isPersonalPortal) {
      this.props.history.push("/portal/PersonalPortal");
    } else {
      this.props.history.push(`/portal/${portal.uri}`);
    }
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  movePortal(portal, direction) {
    var batch = this.props.firebase.db.batch();
    var otherPortalRef = direction === "left" ? portal.priorPortalRef : portal.nextPortalRef;
    otherPortalRef.get().then(function (snap) {
      const targetSequence = snap.get("sequence");
      batch.update(portal.docRef, { sequence: Number(targetSequence) });
      batch.update(otherPortalRef, { sequence: Number(portal.sequence) });
      batch.commit();
    });
  }

  openDeleteDialog() {
    this.setState({ deletingPortal: true });
  }

  closeDeleteDialog() {
    this.setState({ deletingPortal: false });
  }

  render() {
    const { classes, user, isAdmin } = this.props;
    const { portals, loading, portalEditing, deletingPortal } = this.state;

    const isSignedIn = !!user;

    if (!isSignedIn) {
      return (<NoAuthScreen />);
    } else if (loading) {
      return (<CenteredProgressIndicator />);
    }

    var portalsToMap = Array.from(portals);
    if (isAdmin) {
      portalsToMap.push({ isAddNew: true, uid: "addNew" });
    }

    // personal portal
    portalsToMap.push({
      uid: "personalPortal",
      title: personalPortalTitle,
      icon: 'ic-supervisors.svg',
      isPersonalPortal: true,
      colour: '#4F4F4F'
    });

    return (
      <React.Fragment>

        <ConfirmDeleteDialog
          isOpen={deletingPortal}
          onClose={this.closeDeleteDialog}
          onConfirmDelete={this.deletePortal}
        />

        <EditPortalDialog
          portalEditing={portalEditing}
          closeEditDialog={this.closeEditDialog}
          editTitle={this.editTitle}
          editUri={this.editUri}
          editIcon={this.editIcon}
          deletePortal={this.openDeleteDialog}
          saveEditData={this.saveEditData}
        />

        <Box className={classes.mainBox}>
          <Hidden smDown>
            <Typography variant="h1" noWrap>Welcome back {user ? user.displayName : ""}</Typography>
          </Hidden>
          <Hidden mdUp>
            <Typography variant="h1" noWrap>Welcome back</Typography>
          </Hidden>

          <Typography className={classes.homeSubtitle}>Select the portal you wish to visit.</Typography>
          <Box mt={4} style={{ marginLeft: "-15px" }}>
            <RGSContainer fluid style={{ lineHeight: '32px' }}>
              <RGSRow align="end">
                {portalsToMap.map(portal =>
                  <RGSCol key={portal.uid} xs={8} sm={4} md={2} style={{ paddingBottom: "30px" }}>
                    <Tile
                      key={portal.uid}
                      {...portal}
                      isAdmin={isAdmin}
                      type="portalTile"
                      showRightBorder={true}
                      handleClick={() => this.handlePortalClick(portal)}
                      clickEdit={() => this.openEditDialog(portal)}
                      moveTile={(direction) => this.movePortal(portal, direction)}
                      isFirstInSection={portal.priorPortalRef === null}
                      isLastInSection={portal.nextPortalRef === null}
                    />
                  </RGSCol>
                )}
              </RGSRow>
            </RGSContainer>
          </Box>
        </Box>
      </React.Fragment>
    );
  }
}

HomePage = withTracker(HomePage);
HomePage = withStyles(styles, { name: 'HomePage' })(HomePage);
export default withFirebase(HomePage);