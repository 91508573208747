import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { withStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import { ScreenClassProvider } from 'react-grid-system';
import { setUserForTracking } from './Tracker/withTracker';

import StyledSnackbar from './Widgets/StyledSnackbar';
import Bar from './Widgets/Bar';
import HomePage from './Pages/HomePage';
import PortalPage from './Pages/PortalPage/PortalPage';
import NotFoundContent from './Pages/NotFoundPage';
import withRoot from '../withRoot';
import * as ROUTES from '../constants/Routes';
import ActionUtils from '../utils/ActionUtils';

import { withFirebase } from './Firebase';
import LaunchScreen from './Pages/LaunchScreen';
import LoginPage from './Pages/Auth/LoginPage';

const styles = theme => ({
  root: {
    textAlign: 'center',
    paddingTop: theme.spacing(20),
  },
  mainWrapper: {
    paddingLeft: 0,
    paddingRight: 0
  }
});

class App extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.ref = null;
    this.popupSnackbar = this.popupSnackbar.bind(this);
    this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
    this.handleSignOutClick = this.handleSignOutClick.bind(this);
    this.handleToggleAdmin = this.handleToggleAdmin.bind(this);
    this.handleSignInClick = this.handleSignInClick.bind(this);

    this.state = {
      portals: [],
      snackbar: {
        visible: false,
        autoHideDuration: 6000,
        style: "success",
        text: "",
      },
      isAuthReady: false,
      user: null,
      isAdmin: false
    };
  }

  handleSignInClick() {
    this.setState({ isAuthReady: false });
    // begin the sign in process - this will redirect to /auth once complete
    window.location.href = 'https://us-central1-' + process.env.REACT_APP_PROJECT_ID + '.cloudfunctions.net/redirect';
  }

  handleSignOutClick() {
    this.props.firebase.doSignOut();
  }

  popupSnackbar(text, style = "success", duration = 6000) {
    this.setState({
      snackbar: {
        visible: true,
        autoHideDuration: duration,
        style: style,
        text: text,
      }
    });
  }

  handleSnackbarClose() {
    this.setState({
      snackbar: {
        visible: false,
        autoHideDuration: this.state.snackbar.autoHideDuration,
        style: this.state.snackbar.style,
        text: this.state.snackbar.text,
      }
    });
  }

  //App global event handler for reply messages from main electron process when this app is
  //being run inside electron (desktop app version). Attaching here so we dont have to keep
  //attaching and un-attaching when we navigate to portal page
  handleElectronResponseMessage(reply) {
    if (reply.isSuccess) {
      let message = reply.isOpeningBrowser ? "Opening link in default browser" : "Opening external application";
      this.popupSnackbar(message, "success", 3000);
    } else {
      console.error(reply);
      this.popupSnackbar("Error launching external application: " + reply.targetAction, "error");
    }
  }

  handleToggleAdmin() {
    this.setState({ isAdmin: !this.state.isAdmin });
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.removeAuthObserver && this.removeAuthObserver();
  }

  componentDidMount() {
    this._isMounted = true;

    var handler = this.handleElectronResponseMessage.bind(this);
    ActionUtils.attachElectronLinkResponseHandlerCallback(handler);

    this.removeAuthObserver = this.props.firebase.auth.onAuthStateChanged((user) => {
      if (this._isMounted) {
        if (user) {
          // get user data from firestore and combine with firebase auth
          this.unsubUser = this.props.firebase.db.collection('Users').doc(user.uid).onSnapshot(userDoc => {
            let combinedUserData = { ...user, ...userDoc.data() };
            this.setState({ isAuthReady: true, user: combinedUserData });
          });
          setUserForTracking(user.uid); // track login event
        } else {
          this.unsubUser && this.unsubUser();
          this.setState({ isAuthReady: true, isAdmin: false, user: null });
        }
      }
    });
  }

  render() {
    const { classes } = this.props;
    const { isAuthReady, user, isAdmin } = this.state;

    // dev code, uncomment below and comment out state assignment above
    // const { isAuthReady, isAdmin } = this.state;
    // let user = {
    //   displayName: "HRH Billius Bryson the Third",
    //   email: 'billius.bryson@synergy.net.au',
    //   canBeAdmin: true,
    // }

    return (

      <ScreenClassProvider>
        {!isAuthReady &&
          <LaunchScreen />
        }

        {isAuthReady &&
          <Router>
            <div style={{ minHeight: '100vh' }}>
              <Container maxWidth="xl" className={classes.mainWrapper}>
                <Bar
                  title="Info Portal"
                  user={user}
                  isAdmin={isAdmin}
                  onSignInClick={this.handleSignInClick}
                  onSignOutClick={this.handleSignOutClick}
                  onToggleAdmin={this.handleToggleAdmin}
                  popupSnackbar={this.popupSnackbar}
                />
                <Switch>
                  <Route
                    exact
                    path={ROUTES.HOME}
                    render={props => <HomePage
                      {...props}
                      user={user}
                      isAdmin={isAdmin}
                    />}
                  />
                  <Route
                    path={ROUTES.PORTAL}
                    render={props => <PortalPage
                      {...props}
                      user={user}
                      isAdmin={isAdmin}
                      handleSnackbarOpen={this.popupSnackbar}
                    />}
                  />
                  <Route
                    exact={false}
                    path={ROUTES.LOGIN_TOKEN}
                    component={LoginPage}
                  />

                  <Route component={NotFoundContent} />
                </Switch>
                <StyledSnackbar
                  open={this.state.snackbar.visible}
                  variant={this.state.snackbar.style}
                  message={this.state.snackbar.text}
                  closeHandler={this.handleSnackbarClose}
                  autoHideDuration={this.state.snackbar.autoHideDuration}
                />
              </Container>
            </div>
          </Router>
        }
      </ScreenClassProvider>
    );
  }
}

App = withRoot(withStyles(styles)(App));
export default withFirebase(App);
