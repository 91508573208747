import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { withFirebase } from '../Firebase';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import MenuIcon from '@material-ui/icons/Menu';
import SupportIcon from '@material-ui/icons/ContactSupport';
import Hidden from '@material-ui/core/Hidden';
import PersonIcon from '@material-ui/icons/AccountCircle';
import Avatar from '@material-ui/core/Avatar';
import ActionUtils from '../../utils/ActionUtils';
import Button from '@material-ui/core/Button';
import SynergyLogo from '../../assets/synergy_logo_white_text.png';
import SideMenu from './SideMenu';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import Papa from 'papaparse';
import Fuse from 'fuse.js';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { trackEvent } from '../Tracker/withTracker';
import { List, ListItem, ListItemText } from '@material-ui/core';
import AdminList from './Admin/AdminList';

const styles = (theme) => ({
  signUpButton: {
    marginRight: theme.spacing(1)
  },
  titleLink: {
    textDecoration: "none",
    color: theme.palette.primary.main
  },
  avatar: {
    margin: 10,
    color: '#fff',
    backgroundColor: theme.palette.accent.main,
  },
  menuLogo: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(0.5)
  },
  spacer: {
    marginLeft: "auto"
  },
  supportButtonIcon: {
    marginLeft: theme.spacing(1)
  },
  textField: {
    // marginLeft: "auto",
    marginRight: theme.spacing(1),
    // maxWidth: "300",
    '& .MuiInput-root': {
      backgroundColor: "#fff",
      color: "#000",
    },
    '& .Mui-focused': {
      backgroundColor: "#fff",
      color: "#000",
    },
    '&:hover': {
      backgroundColor: "#fff",
      color: "#000",
    },
    '& .MuiInput-underline': {
      borderBottomColor: 'secondary',
    },
    '& .MuiFormLabel-root': {
      color: "#1D294D",
    },
  },
  textInput: {
    backgroundColor: "#fff",
    color: "#000",
  },
  clearButton: {
    color: "#1D294D",
  },
  link: {
    color: "white",
    // textDecoration: "none"
  },
});

class Bar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authMenu: {
        anchorEl: null
      },
      menuOpen: false,
      searchText: "",
      existingIndex: "",
      indexData: [],
      resultAnchor: {
        anchorEl: null
      },
      resultSet: [],
      resultsLength: 0,
      existingAdmins: [],
      updatingIndex: false,
      adminOpen: false,
      enteredEmail: "",
    };

    this.handleChangeSearchText = this.handleChangeSearchText.bind(this);
    this.handleClearSearch = this.handleClearSearch.bind(this);
    this.fetchIndex = this.fetchIndex.bind(this);
    this.updateFirebase = this.updateFirebase.bind(this);
    this.handleSearchKeyPress = this.handleSearchKeyPress.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.updateIndex = this.updateIndex.bind(this);
    this.onEditEmail = this.onEditEmail.bind(this);
    this.onSubmitEmail = this.onSubmitEmail.bind(this);
    this.handleRemoveExistingAdmin = this.handleRemoveExistingAdmin.bind(this);
  }

  updateFirebase() {
    this.subbed = true;
    this.unsubscribe = this.props.firebase
      .index()
      .onSnapshot(snapshot => {
        if (this.state.existingIndex !== snapshot.data().timestamp) {
          this.fetchIndex(snapshot.data().location, snapshot.data().timestamp);
        }
      });
    // maintain list of admins
    this.unsubAdmins = this.props.firebase.db.collection('Users').where('canBeAdmin', '==', true).onSnapshot(adminsSnapshot => {
      this.setState({ existingAdmins: adminsSnapshot.docs });
    });
  }

  componentWillUnmount() {
    this.unsubFirebase();
  }

  componentDidMount() {
    if (!!this.props.user && !this.subbed) {
      this.updateFirebase();
    } else if (!this.props.user) {
      this.unsubFirebase();
    }
  }

  componentDidUpdate() {
    if (!!this.props.user && !this.subbed) {
      this.updateFirebase();
    } else if (!this.props.user) {
      this.unsubFirebase();
    }
  }

  unsubFirebase() {
    this.subbed = false;
    this.unsubscribe && this.unsubscribe();
    this.unsubAdmins && this.unsubAdmins();
  }

  fetchIndex(location, timestamp) {
    this.props.firebase.storage.ref(location).getDownloadURL().then((url) => {
      Papa.parse(url, {
        header: true,
        download: true,
        complete: results => {
          // last row or two seems to be empty
          // keep removing rows from the end until it isn't empty
          let resultData = results.data;
          let rowBackIsEmpty = true;
          while (rowBackIsEmpty) {
            if (!resultData[resultData.length - 1].ref || resultData[resultData.length - 1].ref.length < 2) {
              resultData.pop();
            } else {
              rowBackIsEmpty = false;
            }
          }

          this.setState({ indexData: resultData, existingIndex: timestamp });
        }
      });
    }).catch((error) => {
      console.error(error);
    });
  }

  openAuthMenu = (event) => {
    const anchorEl = event.currentTarget;

    this.setState({
      authMenu: {
        anchorEl
      }
    });
  };

  closeAuthMenu = () => {
    this.setState({
      authMenu: {
        anchorEl: null
      }
    });
  };

  handleSignOutClick = () => {
    this.closeAuthMenu();
    this.props.onSignOutClick();
  };

  handleSignInClick = () => {
    this.closeAuthMenu();
    this.props.onSignInClick();
  };

  toggleAdmin = () => {
    this.closeAuthMenu();
    this.props.onToggleAdmin();
  };

  handleSupportClicked = () => {
    // this link can pre-fill form variables
    const link = "https://synergyprod.service-now.com/csm?id=sc_cat_item&sys_id=a32501ee4face6009ec1d0311310c7fc&comments=Please%20type%20your%20feedback%20on%20a%20new%20line%20here:%20&short_description=Feedback%20for%20Info%20Portal%20application%20(ASSIGN%20TO%20CORE%20SYSTEMS)";
    if (ActionUtils.isInElectronApp()) {
      ActionUtils.handleLinkInElectron(link)
    } else {
      window.open(link);
    }
  }

  openMenu = () => {
    this.setState({ menuOpen: true });
  }

  closeMenu = () => {
    this.setState({ menuOpen: false });
  }

  toggleMenu = () => {
    this.setState({ menuOpen: !this.state.menuOpen });
  }

  openAdmin = () => {
    this.closeAuthMenu();
    this.setState({ adminOpen: true });
  };

  closeAdmin = () => {
    this.setState({ adminOpen: false });
  };

  onEditEmail(text) {
    this.setState({ enteredEmail: text });
  }

  validateEmail(email) {
    return email.includes('@synergy.net.au');
  }

  onSubmitEmail() {
    const email = this.state.enteredEmail;
    this.props.firebase.db.collection('Users').doc(email).set({ canBeAdmin: true }, { merge: true });
    this.onEditEmail("");
  }

  handleRemoveExistingAdmin(adminID) {
    this.props.firebase.db.collection('Users').doc(adminID).set({ canBeAdmin: false }, { merge: true });
  }

  handleChangeSearchText(text) {
    this.setState({ searchText: text });

    const fuse = new Fuse(this.state.indexData, {
      keys: [
        "search",
      ],
    });

    const results = fuse.search(text);
    const resultSet = results.slice(0, 10);

    this.setState({ resultSet: resultSet, resultsLength: results.length });
  }

  handleClearSearch() {
    this.handleChangeSearchText("");
  }

  handleSearchKeyPress(event) {
    if (event.keyCode === 13) {

    }

    const anchorEl = event.currentTarget;

    this.setState({
      resultAnchor: {
        anchorEl
      }
    });
  }

  closeSearchMenu = () => {
    this.setState({
      resultAnchor: {
        anchorEl: null
      }
    });
    this.handleClearSearch();
  };

  handleLinkClick(event, link) {
    if (link.url === "") {
      event.preventDefault();
      this.props.popupSnackbar("This link does not have a url", "error");
      return;
    }

    //We render hyperlinks so people in browser can open normally (right click if they like)
    //but try and catch normal clicks here so we can dispatch events to the desktop Electron app if required
    trackEvent('Link', 'Click', this.props.analyticsPath + link.title);
    if (ActionUtils.isActionSupportedOnClient(link.url)) {
      if (link.url.startsWith("mailto")) {
        event.preventDefault();
        window.location.href = link.url;
      } else if (ActionUtils.isInElectronApp()) {
        event.preventDefault();
        ActionUtils.handleLinkInElectron(link.url);
      } else {
        //TODO: OPEN THE LINK
      }
    } else {
      event.preventDefault();
      trackEvent('Link', 'UnsupportedClient', link.title);
      this.props.popupSnackbar(ActionUtils.getLinkNotSupportedText(), "error");
    }
  }

  async updateIndex() {
    if (this.state.updatingIndex) {
      this.props.popupSnackbar("Already updating index", "warning");
    } else {
      this.closeAuthMenu();
      this.setState({ updatingIndex: true });
      this.props.popupSnackbar("Updating search index", "success");
      const res = await this.props.firebase.functions.httpsCallable('runBuildIndex')();
      if (res.data.success) {
        this.props.popupSnackbar("Search index updated!", "success");
      } else {
        this.props.popupSnackbar("Error updating index!", "error");
      }
      this.setState({ updatingIndex: false });
    }
  }

  render() {
    const { title, user, isAdmin, classes } = this.props;
    const isSignedIn = !!user;

    // Events
    const { authMenu, menuOpen, searchText, resultSet, resultsLength, resultAnchor, existingAdmins, adminOpen, enteredEmail } = this.state;

    const userNameArray = user && user.displayName ? user.displayName.split(" ", 2).map(name => name.charAt(0)) : ["N", "A"];

    let userCanBeAdmin = user ? user.canBeAdmin : false;

    // if we're in a personal portal, the user can be admin
    if (this.props.location.pathname === "/portal/PersonalPortal") {
      userCanBeAdmin = true;
    } else {
      // also check if we're not on a personal portal and need to turn admin off
      if (isAdmin && !userCanBeAdmin) {
        this.toggleAdmin();
      }
    }

    return (
      <React.Fragment>
        <SideMenu
          menuOpen={menuOpen}
          onOpenMenu={this.openMenu}
          onCloseMenu={this.closeMenu}
          isSignedIn={isSignedIn}
        />
        <AdminList
          isOpen={adminOpen}
          onClose={this.closeAdmin}
          enteredEmail={enteredEmail}
          onEditEmail={this.onEditEmail}
          onSubmitEmail={this.onSubmitEmail}
          emailIsValid={this.validateEmail(enteredEmail)}
          existingAdmins={existingAdmins}
          handleRemoveExistingAdmin={this.handleRemoveExistingAdmin}
        />
        <AppBar
          color="inherit"
          position="fixed"
          style={{ boxShadow: "none" }}>
          <Toolbar variant="regular">
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={this.toggleMenu}>
              <MenuIcon />
            </IconButton>

            <Hidden mdDown>
              <Link to="/">
                <img src={SynergyLogo} alt="Synergy Logo" height="40" className={classes.menuLogo} />
              </Link>
            </Hidden>
            <Hidden smDown>
              <Link to="/" className={classes.titleLink}>
                <Typography style={{ flexGrow: 1 }} color="inherit" variant="h6">{title}</Typography>
              </Link>
            </Hidden>

            <Box className={classes.spacer} />

            {isSignedIn &&
              <React.Fragment>
                <TextField
                  className={classes.textField}
                  label={!resultsLength? 'Search' : resultsLength  <= 10 ? `${resultsLength} results` : `10 results shown of ${resultsLength}`}
                  value={searchText}
                  variant="filled"
                  size="small"
                  onChange={event => this.handleChangeSearchText(event.target.value)}
                  onKeyDown={this.handleSearchKeyPress}
                  InputProps={{
                    className: classes.textInput,
                    endAdornment:
                      <IconButton onClick={this.handleClearSearch} className={classes.clearButton} size={"small"}>
                        {searchText === "" ?
                          <SearchIcon />
                          :
                          <ClearIcon />
                        }
                      </IconButton>
                  }}
                />
                <Popper open={Boolean(resultAnchor.anchorEl)} anchorEl={resultAnchor.anchorEl} role={undefined} transition>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper elevation={4}>
                        <ClickAwayListener onClickAway={this.closeSearchMenu}>
                            <List>
                              {resultSet.map((item) =>
                                <ListItem key={item.item.ref}
                                  className={classes.link}
                                  component="a"
                                  onClick={(event) => this.handleLinkClick(event, { title: item.item.search, url: item.item.link })}
                                  href={item.item.link}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  dense
                                  button
                                >
                                  <ListItemText
                                    primary={item.item.search}
                                    secondary={item.item.link.substring(0, 80)}
                                  />
                                </ListItem>
                              )}
                            </List>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </React.Fragment>
            }

            <Hidden smDown>
              <Button className={classes.supportButton} size="medium" variant="outlined" onClick={this.handleSupportClicked}>
                Support
                <SupportIcon className={classes.supportButtonIcon} />
              </Button>
            </Hidden>
            <Hidden mdUp>
              <IconButton onClick={this.handleSupportClicked} className={classes.supportButton}>
                <SupportIcon />
              </IconButton>
            </Hidden>

            {isSignedIn &&
              <React.Fragment>
                <Menu anchorEl={authMenu.anchorEl} open={Boolean(authMenu.anchorEl)} onClose={this.closeAuthMenu}>
                  <MenuItem onClick={this.handleSignOutClick}>Sign out</MenuItem>
                  {userCanBeAdmin &&
                    <React.Fragment>
                      <MenuItem onClick={this.toggleAdmin}>{isAdmin ? "I'm done being admin now, thanks" : "Let me be admin please"}</MenuItem>
                      {user.canBeAdmin &&
                        <React.Fragment>
                          <MenuItem onClick={this.updateIndex}>{"Please rebuild the search index"}</MenuItem>
                          <MenuItem onClick={this.openAdmin}>Manage admins</MenuItem>
                        </React.Fragment>
                      }
                    </React.Fragment>
                  }
                </Menu>
                <IconButton color="inherit" onClick={this.openAuthMenu}>
                  {user.photoURL ? <Avatar alt="Avatar" src={user.photoURL} /> : <Avatar className={classes.avatar}>{userNameArray[0] + userNameArray[1]}</Avatar>}
                </IconButton>
              </React.Fragment>
            }
            {!isSignedIn &&
              <React.Fragment>
                <Menu anchorEl={authMenu.anchorEl} open={Boolean(authMenu.anchorEl)} onClose={this.closeAuthMenu}>
                  <MenuItem onClick={this.handleSignInClick}>Sign in</MenuItem>
                </Menu>
                <IconButton color="inherit" onClick={this.openAuthMenu}>
                  <Avatar className={classes.avatar}><PersonIcon /></Avatar>
                </IconButton>
              </React.Fragment>
            }

          </Toolbar>
          <Box style={{ height: "1px", backgroundColor: "rgba(255, 255, 255, 0.12)", width: "100%" }} />
          {isAdmin &&
            <React.Fragment>
              <Box style={{ backgroundColor: "#102645", width: "100%" }} onClick={this.toggleAdmin}>
                <Typography align="center" style={{ padding: "16px 0", color: "#FFBC51" }} >You are in Admin mode. Click here to exit when you're finished.</Typography>
              </Box>
              <Box style={{ height: "1px", backgroundColor: "rgba(255, 255, 255, 0.12)", width: "100%" }} />
            </React.Fragment>
          }
        </AppBar>
        <Toolbar /> {/* required to fill the space under the fixed appbar */}
      </React.Fragment>
    );
  }
}

Bar = withStyles(styles)(withRouter(Bar));
export default withFirebase(Bar);