import React, { Component } from 'react';
import { withFirebase } from '../../Firebase'
import { withStyles } from '@material-ui/core/styles';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import IconUtils from '../../../utils/IconUtils';
import SVGIconLoader from '../SVGIconLoader';


const styles = (theme) => ({
  root: {
    width: '100%',
    maxWidth: 360,
  },
  icon: {
    marginRight: 10,
    marginBottom: 5
  }
});

class IconPicker extends Component {
  constructor(props) {
    super(props);

    this.handleOpen = this.handleOpen.bind(this);
    this.handleSelectIcon = this.handleSelectIcon.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      anchorElement: null,
      iconOptions: [],
    };
  }

  componentDidMount() {
    this.props.firebase.icons().get().then(iconsSnap => {
      let icons = [];
      iconsSnap.forEach(iconDoc => {
        let section = { ...iconDoc.data(), uid: iconDoc.id };
        icons.push(section);
      });
      this.setState({ iconOptions: icons });
    });
  }

  handleOpen(event) {
    this.setState({ anchorElement: event.currentTarget });
  }

  handleSelectIcon(iconKey) {
    this.props.setSelectedIcon(iconKey);
    this.handleClose();
  }

  handleClose() {
    this.setState({ anchorElement: null });
  }

  getSelectedIconContent() {
    if (this.props.selectedFileName) {
      return (
        <SVGIconLoader
          className={this.props.classes.icon}
          iconURL={IconUtils.getUrlFromIconFileName(this.props.selectedFileName)}
        />
      );
    } else {
      return (<div />)
    }
  }

  render() {
    const { classes, selectedFileName } = this.props;
    const { anchorElement, iconOptions } = this.state;

    let menuTitle = {
      primary: "Pick Icon",
      secondary: selectedFileName !== "" ? "" : "Tap to select icon"
    };

    return (
      <div className={classes.root}>
        <List component="nav">
          <ListItem button onClick={this.handleOpen} {...styles} >
            <ListItemText {...menuTitle} />
            {this.getSelectedIconContent()}
          </ListItem>
        </List>
        <Menu
          anchorEl={anchorElement}
          keepMounted
          open={Boolean(anchorElement)}
          onClose={this.handleClose}
        >
          {iconOptions.map(icon =>
            <MenuItem
              key={icon.uid}
              selected={icon.fileName === selectedFileName}
              onClick={() => this.handleSelectIcon(icon.fileName)}
            >
              <SVGIconLoader
                className={classes.icon}
                iconURL={IconUtils.getUrlFromIconFileName(icon.fileName)}
              />
              {icon.title}
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  }
}

IconPicker = withStyles(styles, { name: 'IconPicker' })(IconPicker);
export default withFirebase(IconPicker);
