
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import IconPicker from './IconPicker';
import ColourPicker from './ColourPicker';

class EditTileDialog extends Component {
  render() {
    const { tileEditing, canCopyTile } = this.props;
    
    if (!tileEditing) {
      return (<div></div>);
    } else return (
      <Dialog open={true} onClose={this.props.closeEditDialog} aria-labelledby="form-dialog-title" fullWidth={true}>
        <DialogTitle id="form-dialog-title">Edit {tileEditing.oldTitle}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="editTitle"
            label="Title"
            fullWidth
            onChange={event => this.props.editTitle(event.target.value)}
            value={tileEditing.title}
          />
          <IconPicker
            selectedFileName={tileEditing.icon}
            setSelectedIcon={this.props.editIcon}
          />
          <ColourPicker
            selectedColourValue={tileEditing.colour}
            setSelectedColour={this.props.editColour}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  checked={tileEditing.hasLinks}
                  value={tileEditing.hasLinks}
                  onChange={this.props.editTileHasLinks}
                />
              }
              label="Tile has multiple links?"
            />
          </FormGroup>
          {!tileEditing.hasLinks &&
            <TextField
              margin="dense"
              id="editActionUrl"
              label="Tile Action URL"
              fullWidth
              onChange={event => this.props.editAction(event.target.value)}
              value={tileEditing.action}
            />
          }
          {tileEditing.hasLinks &&
            <Typography variant="subtitle">To add links, first save, then click the tile to reveal the links section</Typography>
          }
        </DialogContent>
        <DialogActions>
          {canCopyTile &&
            <Button onClick={this.props.clickCopyTile}>
              Copy Tile
              </Button>
          }
          <Button onClick={this.props.deleteTile}>
            Delete Tile
              </Button>
          <Button onClick={this.props.closeEditDialog}>
            Cancel
              </Button>
          <Button onClick={this.props.saveEditData} color="secondary">
            Save
              </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditTileDialog
