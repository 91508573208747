import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Tooltip from "@material-ui/core/Tooltip";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import EditIcon from '@material-ui/icons/Edit';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LinksIcon from '@material-ui/icons/Notes';

import SVGIconLoader from './SVGIconLoader';
import IconUtils from '../../utils/IconUtils';

import LinesEllipsis from 'react-lines-ellipsis';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const styles = (theme) => ({
  tileWithBorder: {
    borderRightWidth: "5px",
    borderRightStyle: "solid",
    borderRightColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.accent.main, // remove this to show colours on pi portal
  },
  tileNoBorder: {
  },
  cardAction: {
    minHeight: "132px"
  },
  constrainedHeightTile: {
    maxHeight: "132px"
  },
  tileSingle: {
    minWidth: "132px",
    maxWidth: "150px"
  },
  tileDouble: {
    minWidth: "14rem",
    maxWidth: "20rem",
  },
  tileText: {
    fontSize: "0.875rem",
    lineHeight: "1rem",
  },
  iconsRight: {
    float: "right",
    lineHeight: "20px",
    textAlign: "right",
  }
});

class Tile extends Component {
  constructor(props) {
    super(props);

    this.onClickEditButton = this.onClickEditButton.bind(this);
    this.handleReflow = this.handleReflow.bind(this);
    this.onClickMoveLeft = this.onClickMoveLeft.bind(this);
    this.onClickMoveRight = this.onClickMoveRight.bind(this);

    this.state = {
      textOverflowed: false
    }
  }

  onClickEditButton(e) {
    this.props.clickEdit();
    e.stopPropagation();
  }

  onClickMoveLeft(e) {
    this.props.moveTile("left");
    e.stopPropagation();
  }

  onClickMoveRight(e) {
    this.props.moveTile("right");
    e.stopPropagation();
  }

  handleReflow = (rleState) => {
    const { clamped } = rleState
    //prevent infinite loop of reflow and only set state if different
    //the reponsive ellipsis tells us if its clamping (...) the text or not
    //which we align with our state to influence tooltips
    if (this.state.textOverflowed !== clamped) {
      this.setState({
        textOverflowed: clamped
      });
    }
  }

  buildAdminControls() {
    return (
      <React.Fragment>
        <div>
          <EditIcon fontSize="small" onClick={this.onClickEditButton} />
        </div>
        {!this.props.isFirstInSection &&
          <div>
            <ArrowLeft fontSize="small" onClick={this.onClickMoveLeft} />
          </div>
        }
        {!this.props.isLastInSection &&
          <div>
            <ArrowRight fontSize="small" onClick={this.onClickMoveRight} />
          </div>
        }
      </React.Fragment>
    );
  }

  render() {
    const { classes, showRightBorder, isAdmin, hasLinks, title, isAddNew } = this.props;

    let tileIconURL = "defaultIcon.svg";
    if (this.props.icon && this.props.icon !== "") {
      tileIconURL = this.props.icon;
    }
    let tileColour = "#2C3A73";
    if (isAddNew) {
      tileColour = "#102645";
    } else if (this.props.colour) {
      tileColour = this.props.colour;
    } else if (this.props.sectionColour) {
      tileColour = this.props.sectionColour;
    }

    const borderClass = showRightBorder ? classes.tileWithBorder : classes.tileNoBorder;
    const cardClass = this.props.type === "singleTile" ? classes.tileSingle : classes.tileDouble;

    return (
      <Card raised={!isAddNew} onClick={() => this.props.handleClick()} className={`${cardClass} ${classes.constrainedHeightTile} ${borderClass}`} style={{ backgroundColor: tileColour }}>
        <CardActionArea component="div" disableRipple className={classes.cardAction}>
          <CardContent>
            <div className={classes.iconsRight} >
              {hasLinks &&
                <div>
                  <Tooltip title="This tile has links">
                    <LinksIcon />
                  </Tooltip>
                </div>
              }
              {!isAddNew && isAdmin && this.buildAdminControls()}
            </div>
            {isAddNew
              ?
              <div style={{ position: "absolute", bottom: "0", right: "0", padding: "20px" }}>
                <Typography variant="subtitle1" style={{ color: "#FFBC51" }}>ADD NEW&nbsp;&nbsp;&nbsp;+</Typography>
              </div>
              :
              <div>
                {tileIconURL !== "" &&
                  <SVGIconLoader
                    iconURL={IconUtils.getUrlFromIconFileName(tileIconURL)}
                    title={title}
                  />
                }
                {/*Tooltip will only show if the responsive ellipsis overflows to ... */}
                <Tooltip title={this.state.textOverflowed ? title : ""}>
                  <ResponsiveEllipsis
                    className={classes.tileText}
                    text={title}
                    maxLine='2'
                    ellipsis='...'
                    trimRight
                    basedOn='letters'
                    onReflow={this.handleReflow}
                  />
                </Tooltip>

              </div>
            }
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
}

Tile.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles, { name: 'Tile' })(Tile);
