/**
 * Gets a sequence one bigger than the last one in the list.
 * Requires the list to be sorted on sequence.
 */
export function getNextSequence(objectArray) {
    if (!objectArray || objectArray.length === 0) {
        return 1;
    } 
    
    const lastObjSequence = objectArray[objectArray.length - 1].sequence;

    if (lastObjSequence) {
        return (lastObjSequence + 1);
    } else {
        return (objectArray.length +1);
    }
}

/**
 * Returns the uri/docID of the personal portal for the user.
 * Uses the first part of the email address (before the @), because it's
 * safe to assume this is unique.
 */
export function getPersonalPortalID(userEmail) {
    let uniqueName = userEmail.split('@')[0].replace('.', '');
    return `PersonalPortal-${uniqueName}`;
}