
import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ColourPicker from './ColourPicker';

class EditPortalSectionDialog extends Component {
  render() {
    const { sectionEditing } = this.props;
    if (!sectionEditing) {
      return (<div></div>);
    } else return (
      <Dialog open={true} onClose={this.props.closeEditDialog} aria-labelledby="form-dialog-title" fullWidth={true}>
        <DialogTitle id="form-dialog-title">Edit {sectionEditing.oldTitle}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="editTitle"
            label="Title"
            fullWidth
            onChange={event => this.props.editTitle(event.target.value)}
            value={sectionEditing.title}
          />
          <ColourPicker
            selectedColourValue={sectionEditing.tileColour}
            setSelectedColour={this.props.editColour}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.deleteSection}>
            Delete Section
                    </Button>
          <Button onClick={this.props.closeEditDialog}>
            Cancel
                    </Button>
          <Button onClick={this.props.saveEditData} color="secondary">
            Save
                    </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditPortalSectionDialog
