import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { withFirebase } from '../Firebase';
import { withRouter } from "react-router-dom";

import List from '@material-ui/core/List';
import CloseIcon from '@material-ui/icons/Close';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Typography, ListItemIcon, IconButton } from '@material-ui/core';

const styles = (theme) => ({
  drawerContent: {
    [theme.breakpoints.down('sm')]: {
      width: 250,
    },
    [theme.breakpoints.up('md')]: {
      width: 315,
    },
  },
  drawer: {
    '& .MuiBackdrop-root': {
      backgroundColor: "rgba(0, 0, 0, 0.3)",
    }
  },
  listItem: {
    marginTop: "10px",
  }
});

class SideMenu extends Component {
  constructor(props) {
    super(props);

    this.handlePortalClick = this.handlePortalClick.bind(this);

    this.state = {
      portals: [],
      subbed: false,
    };
  }

  updateFirebase() {
    this.subbed = true;
    this.unsubscribe = this.props.firebase
      .portals()
      .orderBy('sequence')
      .onSnapshot(snapshot => {
        let portals = [];
        snapshot.forEach(portalDoc => {
          let portal = {
            ...portalDoc.data(),
            uid: portalDoc.id,
            docRef: portalDoc.ref,
          };
          portals.push(portal);
        });

        this.setState({ portals });
      })
  }

  componentWillUnmount() {
    this.unsubFirebase();
  }

  componentDidUpdate() {
    if (this.props.isSignedIn && !this.subbed) {
      this.updateFirebase();
    } else if (!this.props.isSignedIn) {
      this.unsubFirebase();
    }
  }

  unsubFirebase() {
    this.subbed = false;
    this.unsubscribe && this.unsubscribe();
  }

  handlePortalClick(portal) {
    if (portal.isPersonalPortal) {
      this.props.history.push("/portal/PersonalPortal");
    } else {
      this.props.history.push(`/portal/${portal.uri}`);
    }
    this.props.onCloseMenu();
  }

  render() {
    const { classes, menuOpen, isSignedIn } = this.props;
    const { portals } = this.state;

    const isRoot = this.props.history.location.pathname === "/";
    let selectedPortalUri = "";

    if (!isRoot) {
      const pathBits = this.props.history.location.pathname.split('/');
      if (pathBits.length === 3) {
        selectedPortalUri = pathBits[2];
      }
    }

    var portalsToMap = Array.from(portals);
    portalsToMap.push({
      uid: "personalPortal",
      title: "*Your Portal*",
      isPersonalPortal: true,
    });

    return (
      <SwipeableDrawer
        open={menuOpen}
        onClose={this.props.onCloseMenu}
        onOpen={this.props.onOpenMenu}
        className={classes.drawer}>
        <div
          className={classes.drawerContent}
          role="presentation">
          <List>
            <ListItem>
              <ListItemIcon>
                <IconButton onClick={this.props.onCloseMenu} >
                  <CloseIcon />
                </IconButton>
              </ListItemIcon>
              <Typography variant="h5" align="center">Portals</Typography>
            </ListItem>
            {isSignedIn && portalsToMap.map((portal) => (
              <ListItem
                button
                className={classes.listItem}
                key={portal.uid}
                onClick={() => this.handlePortalClick(portal)}
                selected={portal.uri === selectedPortalUri}>
                <ListItemText primary={portal.title} />
              </ListItem>
            ))}
          </List>
        </div>
      </SwipeableDrawer>
    );
  }
}
SideMenu = withStyles(styles, { name: 'HomePage' })(withRouter(SideMenu));
export default withFirebase(SideMenu);