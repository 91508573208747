class ActionUtils {

    static isInElectronApp() {
        return navigator.userAgent.toLowerCase().indexOf(' electron/') > -1;
    }

    static isActionSupportedOnClient(action) {
        if (this.isInElectronApp()) {
            return true;
        } else {
            var actionLower = action.toLowerCase();
            return (actionLower.startsWith('https://') || 
            actionLower.startsWith('http://') || 
            actionLower.startsWith('file://') || 
            actionLower.startsWith('tel:') ||
            actionLower.startsWith('mailto:'));
        }
    }

    static handleLinkInElectron(link) {
        const ipcRenderer = this.getIpcRenderer();
        ipcRenderer.send("launchExe", link);
    }

    static getLinkNotSupportedText() {
        return "Desktop InfoPortal required for this action";
    }

    //Electron main process sends messages back to indicate if it successfully
    //launched a link or not. This function allows render process to attach on
    static attachElectronLinkResponseHandlerCallback(callbackFn) {
        if (!this.isInElectronApp()) {
            return;
        }
        this.logElectonVersionInfo();
        const ipcRenderer = this.getIpcRenderer();
        ipcRenderer.on("launchExeReply", (event, result) => {
            callbackFn(result);
            console.debug('result in portal', result);
        });
    }

    static getIpcRenderer() {
        if(global.ipcRenderer){
            //standard way going forward, preload in desktop client already injected the ipcRenderer
            //to global scope. Means we dont have full node access from this app (good)
            return global.ipcRenderer;
        }else{
            //legacy so we don't break beta users on old client
            return window.require('electron').ipcRenderer;
        }
    }

    static logElectonVersionInfo(){
        if(this.isInElectronApp()){
            //electron app should have injected in its app name and version in the preload
            console.info(window.electronAppName, 'version:', window.electronAppVersion);
        }
    }
}

export default ActionUtils;
