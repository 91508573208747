import React, { Component } from 'react';
import Typography from '@material-ui/core/Typography';
import { Container as RGSContainer, Row as RGSRow, Col as RGSCol } from 'react-grid-system';
import Box from '@material-ui/core/Box';
import ActionUtils from '../../../utils/ActionUtils';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import { trackEvent } from '../../Tracker/withTracker';
import EditLinkDialog from '../../Widgets/Admin/EditLinkDialog';
import ConfirmDeleteDialog from '../../Widgets/Admin/ConfirmDeleteDialog';

const styles = (theme) => ({
  mainBox: {
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8)
    },
    [theme.breakpoints.down('md')]: {
      //We've removed most page margins but need to push left side out as grid has right margins
      //and will look un-even otherwise
      marginLeft: "15px"
    }
  },
  linksSection: {
    width: "100%",
    backgroundColor: "white",
    color: "#414141",
    minHeight: "20rem",
  },
  linksGrid: {
    fontSize: "1rem"
  },
  sectionHeader: {
    paddingTop: "1rem",
    paddingBottom: "1rem"
  },
  linkIcon: {
    paddingRight: "0.5rem",
    fontSize: "80%",
    color: "#016CA6"
  },
  link: {
    color: "#016CA6",
    textDecoration: "none"
  },
  links: {
    paddingLeft: "0px"
  },
  button: {
    margin: theme.spacing(1),
  },
  addButtonIcon: {
    marginLeft: theme.spacing(1),
    color: "#102645"
  },
  fab: {
    borderRadius: "50%",
    height: "22px",
    width: "22px",
    boxShadow: "0px 0px 0px 1px #102645",
    padding: "2px",
    marginLeft: "20px",
  },
  littleIcon: {
    fontSize: "14px",
    color: "#102645"
  }
});

class LinksSection extends Component {
  constructor(props) {
    super(props);

    this.updateFirebase = this.updateFirebase.bind(this);
    this.handleLinkClick = this.handleLinkClick.bind(this);
    this.closeEditDialog = this.closeEditDialog.bind(this);
    this.saveEditData = this.saveEditData.bind(this);
    this.editTitle = this.editTitle.bind(this);
    this.editUrl = this.editUrl.bind(this);
    this.deleteLink = this.deleteLink.bind(this);
    this.addNewLink = this.addNewLink.bind(this);
    this.openEditDialog = this.openEditDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);

    this.state = {
      links: [],
      linkEditing: null,
      lastTileRef: null,
      resubbing: false,
      deletingLink: false
    }
  }

  handleLinkClick(event, link) {
    if (link.url === "") {
      event.preventDefault();
      this.props.popupSnackbar("This link does not have a url", "error");
      return;
    }

    //We render hyperlinks so people in browser can open normally (right click if they like)
    //but try and catch normal clicks here so we can dispatch events to the desktop Electron app if required
    trackEvent('Link', 'Click', this.props.analyticsPath + link.title);
    if (ActionUtils.isActionSupportedOnClient(link.url)) {
      if (link.url.startsWith("mailto")) {
        event.preventDefault();
        window.location.href = link.url;
      } else if (ActionUtils.isInElectronApp()) {
        event.preventDefault();
        ActionUtils.handleLinkInElectron(link.url);
      } else {
        //Do nothing, let the hyperlink normal action flow through that way
        //we dont have pop-up blocker issues etc
      }
    } else {
      event.preventDefault();
      trackEvent('Link', 'UnsupportedClient', link.title);
      this.props.popupSnackbar(ActionUtils.getLinkNotSupportedText(), "error");
    }
  }

  componentWillUnmount() {
    this.unsubFirebase()
  }

  deleteLink() {
    this.state.linkEditing.docRef.delete();
    this.closeDeleteDialog();
    this.closeEditDialog();
  }

  editUrl(text) {
    let linkEditing = this.state.linkEditing;
    linkEditing.url = text;
    this.setState({ linkEditing: linkEditing });
  }

  editTitle(text) {
    let linkEditing = this.state.linkEditing;
    linkEditing.title = text;
    this.setState({ linkEditing: linkEditing });
  }

  saveEditData() {
    this.state.linkEditing.docRef.update({ ...this.state.linkEditing });
    this.closeEditDialog();
  }

  openEditDialog(link) {
    let newLink = {
      docRef: link.docRef,
      title: link.title,
      oldTitle: link.title,
      url: link.url
    };
    this.setState({ linkEditing: newLink });
  }

  closeEditDialog() {
    this.setState({ linkEditing: null });
  }

  componentDidUpdate() {
    if (this.props.visible) {
      this.updateFirebase();
    }
  }

  addNewLink() {
    const editDialog = this.openEditDialog;
    this.props.docRef.collection('links')
      .add({ title: "New Link", url: "" })
      .then((newLinkRef) => {
        newLinkRef.get()
          .then((newLinkSnap) => {
            let link = {
              ...newLinkSnap.data(),
              uid: newLinkSnap.id,
              docRef: newLinkRef
            };
            editDialog(link);
          });
      });
  }

  updateFirebase() {
    // need to unsub and resub if it's different tile than last time
    // this is also called on initial render
    if (this.props.docRef !== this.state.lastTileRef) {
      this.unsubFirebase();
      this.unsubscribe = this.props.docRef.collection('links').orderBy('title').onSnapshot(linksSnap => {
        let links = [];
        linksSnap.forEach(linkDoc => {
          let link = { ...linkDoc.data(), uid: linkDoc.id, docRef: linkDoc.ref }
          links.push(link);
        });
        this.setState({ links: links, resubbing: false });
      });
      this.setState({ lastTileRef: this.props.docRef, resubbing: true });
    }
  }

  unsubFirebase() {
    this.unsubscribe && this.unsubscribe();
  }

  openDeleteDialog() {
    this.setState({ deletingLink: true });
  }

  closeDeleteDialog() {
    this.setState({ deletingLink: false });
  }

  render() {
    if (!this.props.visible) {
      this.unsubFirebase();
      return (<div ref={this.props.innerRef}></div>);
    }

    const { links, linkEditing, resubbing, deletingLink } = this.state;
    const { title, classes } = this.props;

    var linksToMap = Array.from(links);
    if (this.props.isAdmin) {
      linksToMap.push({ isAddNew: true, uid: "addNew" });
    }

    return (
      <React.Fragment>
        <ConfirmDeleteDialog
          isOpen={deletingLink}
          onClose={this.closeDeleteDialog}
          onConfirmDelete={this.deleteLink}
        />
        <EditLinkDialog
          linkEditing={linkEditing}
          closeEditDialog={this.closeEditDialog}
          editTitle={this.editTitle}
          editUrl={this.editUrl}
          deleteLink={this.openDeleteDialog}
          saveEditData={this.saveEditData}
        />
        <div className={classes.linksSection} ref={this.props.innerRef}>
          <Box className={classes.mainBox}>
            <Box style={{ marginLeft: "-15px" }} className={classes.linksGrid}>
              <RGSContainer fluid style={{ lineHeight: '32px' }}>
                <RGSRow>
                  <RGSCol>
                    <Typography variant="h4" className={classes.sectionHeader} noWrap>{title}</Typography>
                  </RGSCol>
                </RGSRow>
                <RGSRow>
                  {!resubbing && linksToMap.map(link => (
                    <RGSCol xs={8} sm={8} md={4} lg={4} xl={4} style={{ paddingBottom: "5px" }} key={link.uid}>
                      {link.isAddNew ?
                        <Button variant="outlined" className={classes.button} color="secondary" onClick={() => this.addNewLink()}>
                          ADD NEW
                                                    <AddIcon className={classes.addButtonIcon} />
                        </Button>
                        :
                        <Box>
                          <span className={classes.linkIcon}>&gt;</span>
                          <a
                            className={classes.link}
                            onClick={(event) => this.handleLinkClick(event, link)}
                            href={link.url}
                            target="_blank"
                            rel="noopener noreferrer">
                            {link.title}
                          </a>
                          {this.props.isAdmin &&
                            <IconButton onClick={() => this.openEditDialog(link)} className={classes.fab}>
                              <EditIcon className={classes.littleIcon} />
                            </IconButton>
                          }
                        </Box>
                      }
                    </RGSCol>
                  ))}
                </RGSRow>
              </RGSContainer>
            </Box>
          </Box>
        </div>
      </React.Fragment>
    );
  }
}

const LinksComponent = React.forwardRef((props, ref) => <LinksSection innerRef={ref} {...props} />);
//const StyledComponent = withStyles({styles})(LinksComponent);
const StyledComponent = withStyles(styles, { name: 'LinksSection' })(LinksComponent);
export default StyledComponent;