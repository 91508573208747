import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Typography, Button } from '@material-ui/core';

class ConfirmDeleteDialog extends Component {

  render() {
    const { isOpen, onClose, onConfirmDelete } = this.props;

    return (
      <Dialog open={isOpen} onClose={onClose} fullWidth={true}>
        <DialogTitle>Delete?</DialogTitle>
        <DialogContent>
          <Typography>
            This is immediate and can't be undone. You need to be sure.
                </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            No, wait
                        </Button>
          <Button onClick={onConfirmDelete} color="secondary">
            I said delete
                        </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default ConfirmDeleteDialog
