//Standard implementation provided off reat-ga page
//we've just modified to expose the event tracking capability
//as we use events for actions like clicking tiles which dont change the URI
import React, { Component, } from "react";
import GoogleAnalytics from "react-ga";
import ActionUtils from '../../utils/ActionUtils';

const trackingCode = process.env.REACT_APP_GOOGLE_ANALYTICS_TACKING_CODE;

GoogleAnalytics.initialize(trackingCode, {
  debug: false,
  titleCase: false
});

const isInElectronApp = ActionUtils.isInElectronApp();
GoogleAnalytics.set({ appName: isInElectronApp ? 'Electron Client' : 'Browser' });
if (isInElectronApp && window.electronAppVersion) {
  GoogleAnalytics.set({ appVersion: window.electronAppVersion });
}

const withTracker = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    GoogleAnalytics.set({
      page,
      ...options,
    });
    GoogleAnalytics.pageview(page);
  };

  // eslint-disable-next-line
  const HOC = class extends Component {
    componentDidMount() {
      // eslint-disable-next-line
      const page = this.props.location.pathname + this.props.location.search;
      trackPage(page);
    }

    componentDidUpdate(prevProps) {
      const currentPage =
        prevProps.location.pathname + prevProps.location.search;
      const nextPage =
        this.props.location.pathname + this.props.location.search;

      if (currentPage !== nextPage) {
        trackPage(nextPage);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  };

  return HOC;
};

export default withTracker;
export const setUserForTracking = (userID) => GoogleAnalytics.set({ userId: userID });

export const trackEvent = (category, action, label) => GoogleAnalytics.event({ category, action, label });