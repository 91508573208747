import React, { Component } from 'react';

import SVG from 'react-inlinesvg';

import BrokenImage from '@material-ui/icons/BrokenImage';

/* Wraps the inline SVG library we use for loading tile icons with our defaults
   so code to load tile icon is same in all places of app */
class SVGIconLoader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      iconURL: this.props.iconURL,
      isError: false
    };
  }

  setSVGColour(svgXML) {
    if (this.props.colour) {
      //look for fill="xxxx" in the SVG XML and replace with the colour values
      //but don't replace fill="none" as that would mess icons up
      return svgXML.replace(/fill="(?!none).*?"/g, `fill="${this.props.colour}"`)
    }
    else return svgXML;
  }

  componentDidUpdate(prevProps) {
    if (this.props.iconURL !== prevProps.iconURL) {
      this.setState({
        iconURL: this.props.iconURL,
        isError: false
      });
    }
  }

  render() {
    const title = this.props.title ? `Icon for ${this.props.title}` : "";
    if (!this.state.isError) {
      return (<SVG
        cacheRequests={true}
        loader={<span>Loading...</span>}
        onError={error => this.setState({ isError: true })}
        preProcessor={code => this.setSVGColour(code)}
        src={this.state.iconURL}
        title={title}
      />);
    } else {
      return (
        <BrokenImage
          htmlColor={this.props.colour}
        />
      );
    }
  }
}
export default SVGIconLoader;
