import React, { Component } from 'react';

import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  center: {
    paddingTop: '10vh',
  }
});

class NoAuthScreen extends Component {
  render() {
    // Styling
    const { classes } = this.props;

    return (
      <div className={classes.center}>
        <Typography variant="h1" align="center">Please log in to view content.</Typography>
        <Typography variant="h1" align="center">(Click the person icon in the top right).</Typography>
      </div>
    );
  }
}

export default withStyles(styles)(NoAuthScreen);