import React, { Component, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import Tile from '../../Widgets/Tile';
import { withStyles } from '@material-ui/core/styles';
import { withFirebase } from '../../Firebase';
import { setConfiguration, Container as RGSContainer, Row as RGSRow, Col as RGSCol } from 'react-grid-system';

import Box from '@material-ui/core/Box';

import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import ArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import ArrowRight from '@material-ui/icons/KeyboardArrowRight';
import EditTileDialog from '../../Widgets/Admin/EditTileDialog';
import ConfirmDeleteDialog from '../../Widgets/Admin/ConfirmDeleteDialog';
import * as Helper from '../../../utils/Helper.js';
import CopyTileDialog from '../../Widgets/Admin/CopyTileDialog';

setConfiguration({ gridColumns: 8 });

const styles = (theme) => ({
  sectionHeaderWithBreak: {
    marginLeft: "15px",
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2)
  },
  sectionHeaderNoBreak: {
    paddingBottom: theme.spacing(2),
  },
  fab: {
    borderRadius: "50%",
    height: "30px",
    width: "30px",
    boxShadow: "0px 0px 0px 2px #FFBC51",
    padding: "0.1rem 0.1rem",
    margin: "0.25rem 0.25rem",
  },
  littleIcon: {
    fontSize: "1.2rem"
  }
});


class PortalSection extends Component {
  constructor(props) {
    super(props);

    this.openEditDialog = this.openEditDialog.bind(this);
    this.closeEditDialog = this.closeEditDialog.bind(this);
    this.saveEditData = this.saveEditData.bind(this);
    this.editTitle = this.editTitle.bind(this);
    this.editTileHasLinks = this.editTileHasLinks.bind(this);
    this.deleteTile = this.deleteTile.bind(this);
    this.editIcon = this.editIcon.bind(this);
    this.editColour = this.editColour.bind(this);
    this.editAction = this.editAction.bind(this);
    this.addNewTile = this.addNewTile.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.openCopyDialog = this.openCopyDialog.bind(this);
    this.copyTile = this.copyTile.bind(this);
    this.closeCopyDialog = this.closeCopyDialog.bind(this);

    this.state = {
      tiles: [],
      tileEditing: null,
      deletingTile: false,
      tileCopying: null,
    };
  }

  handleTileClick(tile) {
    if (tile.isAddNew) {
      this.addNewTile(tile.isNewSection);
    } else {
      this.props.handleTileClick(tile);
    }
  }

  addNewTile(isNewSection) {
    if (isNewSection) {
      this.props.createNewSection();
    } else {
      const editDialog = this.openEditDialog;
      this.props.docRef.collection('tiles')
        .add({ title: "New Tile", sequence: Helper.getNextSequence(this.state.tiles) })
        .then((newTileRef) => {
          newTileRef.get()
            .then((newTileSnap) => {
              let tile = {
                ...newTileSnap.data(),
                uid: newTileSnap.id,
                docRef: newTileRef
              };
              editDialog(tile);
            });
        });
    }
  }

  openEditDialog(tile) {
    // can't have undefined going to firebase
    const hasLinks = tile.hasLinks ? tile.hasLinks : false;
    const icon = tile.icon ? tile.icon : "";
    const colour = tile.colour ? tile.colour : "";
    const action = tile.action ? tile.action : "";

    let newTile = {
      docRef: tile.docRef,
      title: tile.title,
      oldTitle: tile.title,
      icon: icon,
      hasLinks: hasLinks,
      sequence: tile.sequence,
      colour: colour,
      action: action,
    };
    this.setState({ tileEditing: newTile });
  }

  componentDidMount() {
    if (!this.props.isAddNew) {
      this.unsubscribe = this.props.docRef
        .collection('tiles')
        .orderBy('sequence')
        .onSnapshot(tilesSnap => {
          let tiles = [];
          let priorTileRef = null; // track what tile to swap with when moving left or right
          tilesSnap.forEach(tileDoc => {
            let tile = {
              ...tileDoc.data(),
              uid: tileDoc.id,
              docRef: tileDoc.ref,
              isNewSection: false,
              priorTileRef: priorTileRef,
              nextTileRef: null
            };
            tile.analyticsPath = this.props.analyticsPath + tile.title + "/";
            tiles.push(tile);
            //Storing next and prior ref to simplify re-ordering tiles, avoids lookups
            //update to self for next loop iteration
            priorTileRef = tileDoc.ref
            if (tiles.length > 1) {
              tiles[tiles.length - 2].nextTileRef = tileDoc.ref;
            }
          });
          this.setState({ tiles: tiles });
        });
    } else {
      // need to show a tile to add a new section
      let tiles = [];
      tiles.push({ isAddNew: true, uid: "addNew", isNewSection: true });
      this.setState({ tiles: tiles });
    }
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  closeEditDialog() {
    this.setState({ tileEditing: null });
  }

  saveEditData() {
    this.state.tileEditing.docRef.update({ ...this.state.tileEditing });
    this.closeEditDialog();
  }

  editTitle(text) {
    let tileEditing = this.state.tileEditing;
    tileEditing.title = text;
    this.setState({ tileEditing: tileEditing });
  }

  editIcon(text) {
    let tileEditing = this.state.tileEditing;
    tileEditing.icon = text;
    this.setState({ tileEditing: tileEditing });
  }

  editColour(text) {
    let tileEditing = this.state.tileEditing;
    tileEditing.colour = text;
    this.setState({ tileEditing: tileEditing });
  }

  editTileHasLinks() {
    let tileEditing = this.state.tileEditing;
    tileEditing.hasLinks = !tileEditing.hasLinks;
    this.setState({ tileEditing: tileEditing });
  }

  editAction(text) {
    let tileEditing = this.state.tileEditing;
    tileEditing.action = text;
    this.setState({ tileEditing: tileEditing });
  }

  deleteTile() {
    this.state.tileEditing.docRef.delete();
    this.closeDeleteDialog();
    this.closeEditDialog();
  }

  openCopyDialog() {
    this.setState({ tileCopying: this.state.tileEditing });
  }

  copyTile(sectionRef) {
    sectionRef.collection('tiles').get().then(tileSnap => {
      let tiles = tileSnap.docs.map(tile => tile.data());
      sectionRef.collection('tiles').add({ ...this.state.tileEditing, sequence: Helper.getNextSequence(tiles) })
        .then((newTileRef) => {
          this.state.tileEditing.docRef.collection('links').orderBy('title').get().then(linkSnap => {
            let linksToCopy = linkSnap.docs.map(link => link.data());
            linksToCopy.forEach(link => newTileRef.collection('links').add(link));
            this.closeEditDialog();
          });
        });
    });

  }

  closeCopyDialog() {
    this.setState({ tileCopying: null });
  }

  moveTile(tile, direction) {
    let batch = this.props.firebase.db.batch();
    let otherTileRef = direction === "left" ? tile.priorTileRef : tile.nextTileRef;
    otherTileRef.get().then(function (snap) {
      const targetSequence = snap.get("sequence");
      batch.update(tile.docRef, { sequence: Number(targetSequence) });
      batch.update(otherTileRef, { sequence: Number(tile.sequence) });
      batch.commit();
    });
  }

  getTile(tile) {
    return (
      <Tile
        key={tile.uid}
        {...tile}
        isAdmin={this.props.isAdmin}
        sectionColour={this.props.tileColour}
        type={this.props.alreadyInGrid ? "singleTile" : "portalTile"}
        showRightBorder={!this.props.alreadyInGrid}
        handleClick={() => this.handleTileClick(tile)}
        clickEdit={() => this.openEditDialog(tile)}
        moveTile={(direction) => this.moveTile(tile, direction)}
        isFirstInSection={tile.priorTileRef === null}
        isLastInSection={tile.nextTileRef === null}
      />);
  }

  openDeleteDialog() {
    this.setState({ deletingTile: true });
  }

  closeDeleteDialog() {
    this.setState({ deletingTile: false });
  }

  render() {
    const { tiles, tileEditing, deletingTile, tileCopying } = this.state;
    const { title, classes, isAdmin, isAddNew, alreadyInGrid, isFirstSection, isLastSection, canCopyTiles } = this.props;

    let tilesToMap = Array.from(tiles);
    if (isAdmin && !isAddNew) {
      tilesToMap.push({ isAddNew: true, uid: "addNew", isNewSection: false });
    }

    const isCosy = this.props.portalStyle === "cosy";
    const sectionTitle = !isAddNew ? title : "*New section*";
    const isEditable = isAdmin && !isAddNew;

    const colSettings = isCosy ?
      { xs: 4, sm: 2, md: 2, lg: 2, xl: 1 }
      :
      { xs: 8, sm: 4, md: 4, lg: 4, xl: 2 };

    const titleStyles = isCosy ? {} : { marginLeft: "16px" };

    const titleComponent = <Box component="span">
      <div style={alreadyInGrid ? {} : { marginLeft: "15px", marginBottom: "10px" }}>
        {isEditable &&
          <React.Fragment>
            <IconButton onClick={() => this.props.clickEditSection()} color="secondary" className={classes.fab}>
              <EditIcon fontSize="small" />
            </IconButton>
            {!isFirstSection &&
              <IconButton onClick={() => this.props.handleMoveSection("left")} size="small" color="secondary" aria-label="move left" className={classes.fab}>
                <ArrowLeft />
              </IconButton>
            }
          </React.Fragment>
        }
        {isEditable && !isLastSection &&
          <React.Fragment>
            <IconButton onClick={() => this.props.handleMoveSection("right")} size="small" color="secondary" aria-label="move right" className={classes.fab}>
              <ArrowRight />
            </IconButton>
          </React.Fragment>
        }
      </div>
      <div>
        <Typography variant="h2" display="inline" style={titleStyles} className={classes.sectionHeaderNoBreak}>{sectionTitle}</Typography>
      </div>
    </Box>;

    const tilesComponent = tilesToMap.map((tile, index) =>
      <RGSCol key={tile.uid + "col"} {...colSettings} style={{ paddingBottom: "30px" }}>
        {isCosy && index === 0 && titleComponent}
        {this.getTile(tile)}
      </RGSCol>
    );

    return (
      <Fragment>
        <ConfirmDeleteDialog
          isOpen={deletingTile}
          onClose={this.closeDeleteDialog}
          onConfirmDelete={this.deleteTile}
        />
        <EditTileDialog
          tileEditing={tileEditing}
          closeEditDialog={this.closeEditDialog}
          editTitle={this.editTitle}
          editIcon={this.editIcon}
          editColour={this.editColour}
          editTileHasLinks={this.editTileHasLinks}
          editAction={this.editAction}
          deleteTile={this.openDeleteDialog}
          saveEditData={this.saveEditData}
          clickCopyTile={this.openCopyDialog}
          canCopyTile={canCopyTiles}
        />
        <CopyTileDialog
          tileCopying={tileCopying}
          copyTile={this.copyTile}
          closeCopyDialog={this.closeCopyDialog}
        />
        {!isCosy && titleComponent}
        {alreadyInGrid
          ?
          tilesComponent
          :
          <RGSContainer fluid style={{ lineHeight: '32px', marginTop: "20px" }}>
            <RGSRow align="end">
              {tilesComponent}
            </RGSRow>
          </RGSContainer>
        }
      </Fragment>
    );
  }
}

PortalSection = withStyles(styles, { name: 'PortalSection' })(PortalSection);
export default withFirebase(PortalSection);