import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

class AdminList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorElement: null,
    };

    this.handleOpenAdminsList = this.handleOpenAdminsList.bind(this);
    this.handleCloseAdminsList = this.handleCloseAdminsList.bind(this);
  }

  handleOpenAdminsList(event) {
    this.setState({ anchorElement: event.currentTarget });
  }

  handleCloseAdminsList() {
    this.setState({ anchorElement: null });
  }

  render() {
    const { isOpen, onClose, enteredEmail, onEditEmail, onSubmitEmail, emailIsValid, existingAdmins } = this.props;
    const { anchorElement } = this.state;

    return (
      <Dialog open={isOpen} onClose={onClose} fullWidth={true}>
        <DialogTitle>Manage Admins</DialogTitle>
        <DialogContent>
          <TextField
            margin="normal"
            label="Add new admin by email address"
            type="email"
            fullWidth
            onChange={event => onEditEmail(event.target.value)}
            value={enteredEmail}
          />
          <Box component="span" mr={2}>
            <Button variant="contained" color={'secondary'} disabled={!emailIsValid} onClick={onSubmitEmail}>
              Add entered user
            </Button>
          </Box>
          <Button variant="outlined" onClick={this.handleOpenAdminsList}>
            Remove existing admins
          </Button>
          {(existingAdmins.length > 0) &&
            <Menu
              anchorEl={anchorElement}
              keepMounted
              open={Boolean(anchorElement)}
              onClose={this.handleCloseAdminsList}
            >
              {existingAdmins.map(admin =>
                <MenuItem
                  key={admin.id}
                  onClick={() => this.props.handleRemoveExistingAdmin(admin.id)}
                >
                  {admin.id}
                </MenuItem>
              )}
            </Menu>
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AdminList