import React, { Component } from 'react';
import { withFirebase } from '../../Firebase'

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';

import { Typography } from '@material-ui/core';

class CopyTileDialog extends Component {
  constructor(props) {
    super(props);

    this.handleOpenPortalList = this.handleOpenPortalList.bind(this);
    this.handleSelectPortal = this.handleSelectPortal.bind(this);
    this.handleClosePortalList = this.handleClosePortalList.bind(this);
    this.handleOpenSectionList = this.handleOpenSectionList.bind(this);
    this.handleSelectSection = this.handleSelectSection.bind(this);
    this.handleCloseSectionList = this.handleCloseSectionList.bind(this);
    this.handleClickCopy = this.handleClickCopy.bind(this);
    this.handleCloseDialog = this.handleCloseDialog.bind(this);

    this.state = {
      anchorElementPortalList: null,
      portals: [],
      selectedPortalUid: null,
      anchorElementSectionList: null,
      sections: [],
      selectedSectionUid: null
    };
  }

  componentDidMount() {
    this.unsubscribe = this.props.firebase
      .portals()
      .orderBy('sequence')
      .onSnapshot(snapshot => {
        let portals = [];
        snapshot.forEach(portalDoc => {
          let portal = {
            ...portalDoc.data(),
            uid: portalDoc.id,
            docRef: portalDoc.ref,
          };
          portals.push(portal);
        });

        this.setState({ portals });
      })
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  handleOpenPortalList(event) {
    this.setState({ anchorElementPortalList: event.currentTarget });
  }

  handleSelectPortal(portal) {
    this.setState({ selectedPortalUid: portal.uid });
    this.handleClosePortalList();
    this.setState({ sections: [], selectedSectionUid: null });

    portal.docRef.collection('sections').orderBy('sequence').get().then(sectionsSnap => {
      let sections = [];
      sectionsSnap.forEach(sectionDoc => {
        let section = {
          ...sectionDoc.data(),
          uid: sectionDoc.id,
          docRef: sectionDoc.ref,
        };
        sections.push(section);
      });
      this.setState({ sections: sections });
    });
  }

  handleClosePortalList() {
    this.setState({ anchorElementPortalList: null });
  }

  handleOpenSectionList(event) {
    this.setState({ anchorElementSectionList: event.currentTarget });
  }

  handleSelectSection(section) {
    this.setState({ selectedSectionUid: section.uid });
    this.handleCloseSectionList();
  }

  handleCloseSectionList() {
    this.setState({ anchorElementSectionList: null });
  }

  handleClickCopy() {
    let section = this.state.sections.find(section => section.uid === this.state.selectedSectionUid);
    this.props.copyTile(section.docRef);
    this.handleCloseDialog();
  }

  handleCloseDialog() {
    // need to clear the state as this widget never actually dies
    this.setState({ selectedPortalUid: null, sections: [], selectedSectionUid: null });
    this.props.closeCopyDialog();
  }

  render() {
    const { tileCopying } = this.props;
    const { anchorElementPortalList, portals, selectedPortalUid, anchorElementSectionList, sections, selectedSectionUid } = this.state;

    let portalMenuTitle = { primary: "Select portal" };
    if (selectedPortalUid) {
      const pickedPortal = portals.find(portal => portal.uid === selectedPortalUid);
      portalMenuTitle.primary = pickedPortal ? pickedPortal.title : "*Untitled portal*";
      portalMenuTitle.secondary = "Click to change";
    }

    let sectionMenuTitle = { primary: "Select section" };
    if (selectedSectionUid) {
      const pickedSection = sections.find(section => section.uid === selectedSectionUid);
      sectionMenuTitle.primary = pickedSection ? pickedSection.title : "*Untitled section*";
      sectionMenuTitle.secondary = "Click to change";
    }

    if (!tileCopying) {
      return (<div></div>);
    } else {
      return (
        <Dialog open={true} onClose={this.props.closeCopyDialog} aria-labelledby="form-dialog-title" fullWidth={true}>
          <DialogTitle id="form-dialog-title">Copy tile</DialogTitle>
          <DialogContent>

            <Typography>Select where to copy the tile to</Typography>
            <List component="nav">
              <ListItem button onClick={this.handleOpenPortalList} >
                <ListItemText  {...portalMenuTitle} />
              </ListItem>
            </List>
            <Menu
              anchorEl={anchorElementPortalList}
              keepMounted
              open={Boolean(anchorElementPortalList)}
              onClose={this.handleClosePortalList}
            >
              {portals.map(portal =>
                <MenuItem
                  key={portal.uid}
                  selected={portal.uid === selectedPortalUid}
                  onClick={() => this.handleSelectPortal(portal)}
                >
                  {portal.title}
                </MenuItem>
              )}
            </Menu>
            {selectedPortalUid &&
              <div>
                <List component="nav">
                  <ListItem button onClick={this.handleOpenSectionList} >
                    <ListItemText  {...sectionMenuTitle} />
                  </ListItem>
                </List>
                <Menu
                  anchorEl={anchorElementSectionList}
                  keepMounted
                  open={Boolean(anchorElementSectionList)}
                  onClose={this.handleCloseSectionList}
                >
                  {sections.map(section =>
                    <MenuItem
                      key={section.uid}
                      selected={section.uid === selectedSectionUid}
                      onClick={() => this.handleSelectSection(section)}
                    >
                      {section.title}
                    </MenuItem>
                  )}
                </Menu>
              </div>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseDialog}>
              Cancel
              </Button>
            <Button onClick={this.handleClickCopy} color="secondary">
              Copy
              </Button>
          </DialogActions>
        </Dialog>
      );
    }
  }
}

export default withFirebase(CopyTileDialog);
