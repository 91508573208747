import React, { Component } from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import IconPicker from './IconPicker';
import { Typography } from '@material-ui/core';

class EditPortalDialog extends Component {
  render() {
    const { portalEditing } = this.props;
    if (!portalEditing) {
      return (<div></div>);
    } else return (
      <Dialog open={true} onClose={this.props.closeEditDialog} aria-labelledby="form-dialog-title" fullWidth={true}>
        <DialogTitle id="form-dialog-title">Edit {portalEditing.oldTitle}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="editTitle"
            label="Title"
            fullWidth
            onChange={event => this.props.editTitle(event.target.value)}
            value={portalEditing.title}
          />
          <TextField
            autoFocus
            margin="dense"
            id="editUri"
            label="Portal URL"
            fullWidth
            onChange={event => this.props.editUri(event.target.value)}
            value={portalEditing.uri}
          />
          <Typography>This is the portal's url address. It is alphanumeric and MUST be unique</Typography>
          <IconPicker
            selectedFileName={portalEditing.icon}
            setSelectedIcon={this.props.editIcon}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.deletePortal}>
            Delete Tile
          </Button>
          <Button onClick={this.props.closeEditDialog}>
            Cancel
          </Button>
          <Button onClick={this.props.saveEditData} color="secondary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default EditPortalDialog
