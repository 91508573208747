import React, { Component } from 'react';

import SentimentVeryDissatisfied from '@material-ui/icons/SentimentVeryDissatisfied';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

class NotFoundContent extends Component {
  render() {

    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ minHeight: '80vh' }}
      >
        <Grid item xs={12} sm={12}>
          <Typography variant="h1">Page Not Found</Typography>
        </Grid>
        <Grid item xs={12} sm={12}>
          <SentimentVeryDissatisfied style={{ fontSize: 120, paddingTop: 10, paddingBottom: 10 }} color="secondary" />
        </Grid>
        <Grid item xs={12} sm={12}>
          <Typography>Sorry, we can't find the page you are after. <Link href="/" color="secondary">Back to Home</Link></Typography>
        </Grid>
      </Grid>
    );
  }
}

export default NotFoundContent